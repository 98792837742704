import * as bootstrap from 'bootstrap'
import { Splide } from '@splidejs/splide'
import ProgressBar from 'progressbar.js'
import * as QRCode from 'qrcode'
import * as panzoom from 'panzoom'
import introJs from 'intro.js'
import 'dayjs/locale/fr.js'
import dayjs from 'dayjs'
import List from 'list.js'
dayjs.locale('fr')

// make bootstrap available globally
window.bootstrap = bootstrap
window.Splide = Splide
window.panzoom = panzoom
window.introJs = introJs
window.dayjs = dayjs
window.ProgressBar = ProgressBar
window.QRCode = QRCode
window.List = List
